import React from 'react'
import Layout from '../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import { Container, Col, Row } from 'react-bootstrap';
import logoBlack from '../assets/images/parsity-logo-black.png';

const LinksContainer = styled(Container)`
  h1 {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  margin-top: 100px;

  .white-logo {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }
`

const PSButtom = styled.a`
  margin: 0 auto;
  background: #fff;
  border: 2px solid #000;
  padding: 15px 20px;
  display: block;
  text-align: center;
  color: #000;
  text-decoration: none;
  box-shadow: #000 -10px 8px 0px;
  letter-spacing: 2px;
  font-size: 20px;
  min-width: 150px;
  text-align: center;
  font-family: 'roboto-mono';
  max-width: 400px;
  margin-top: 30px;
  &:hover {
    background: var(--yellow);
    box-shadow: none;
    color: var(--black);
  }
`

const Links = () => {
  return (
    <Layout>
      <LinksContainer fluid>
        <Row>
          <Col md={12}>
            <img className='white-logo' src={logoBlack} alt=''></img>
            <PSButtom target='_blank' href='https://docs.google.com/document/d/1a_N1vKpItjhbdPWDfgC2fnbiqLaaxIzNF9S5t1C_Pms/edit?usp=sharing' className="ps-btn-main">2022 Goal Setting Template</PSButtom>
            <PSButtom href='https://dev30.xyz' className="ps-btn-main" target='_blank'>dev30</PSButtom>
          </Col>
        </Row>
      </LinksContainer>
    </Layout>
  )
}

export default Links
